import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout, Menu, Icon, Dropdown, Avatar, message } from 'antd';

import ManualsAndPoliciesContainer from '../../manualsAndPolicies/containers/ManualsAndPoliciesContainer';
import TradeRequestContainer from '../../tradeRequest/containers/TradeRequestContainer';
import AccountHoldingContainer from '../../personalAccountHolding/containers/AccountHoldingContainer';
import GiftHospitalityRequestContainer from '../../giftHospitalityRequest/containers/GiftHospitalityRequestContainer';
import RestrictedSecurityRequestContainer from '../../restrictedSecurityRequest/containers/RestrictedSecurityRequestContainer';
import QuestionnaireRequestContainer from '../../questionnaireRequest/containers/QuestionnaireRequestContainer';
import LicensingContainer from '../../licensing/containers/LicensingContainer';
import GeneralAgreementContainer from '../../agreement/containers/GeneralAgreementContainer';

import MeetingAndTrainingContainer from '../../meetingAndTraining/containers/MeetingAndTrainingContainer';
import CollegeApplicationContainer from '../../college-application/containers/CollegeApplicationContainer';
import MeetingAndTrainingCalenderContainer from '../../meetingAndTrainingCalender/containers/MeetingAndTrainingCalenderContainer';
import ExpenseSummaryNewContainer from '../../expense-summary-new/containers/ExpenseSummaryNewContainer';
import { localStorageClient } from '../../../../utils/localStorageClient';
import ReportingSummaryContainer from '../../reporting-summary/containers/ReportingSummaryContainer';
import WorkManagementContainer from '../../work-management/containers/WorkManagementContainer';

const { Header, Content } = Layout;

class Home extends Component {
  state = {
    visible: false,
  };
  
  componentDidMount () {
    // Load Fund Book Data
    this.props.loadFundBookData();

    // Load Security Tickers
    this.props.loadSecurityTickers();

    // Load Asset Inst Class
    this.props.loadAssetInstClass();

    // get hierarchy menus
    this.startHierarchyMenusRefreshTask();
    this.props.loadUserAccounts();
  }



  componentWillUnmount() {
    this.stopHierarchyMenusRefreshTask();
  }


  hierarchyMenusRefreshTask = null;
  startHierarchyMenusRefreshTask = () => {
    this.props.getHierarchyMenus();
    this.hierarchyMenusRefreshTask =  setInterval(() => {
      this.props.getHierarchyMenus();
    }, 1*60*60*1000);  // 每1小时
  }
  stopHierarchyMenusRefreshTask = () => {
    if(!!this.hierarchyMenusRefreshTask) {
      clearInterval(this.hierarchyMenusRefreshTask);
    }
  }


  // 获得当前访问的菜单
  getCurrMenu = () => {
    const pathname = window.location.pathname;
    let currMenu = null;
    const flatMenus = this.props.flatMenus;
    if(!!flatMenus) {
      const selectedMenu = flatMenus.filter(e => e.module === pathname);
      if(!!selectedMenu && selectedMenu.length > 0) {
        currMenu = selectedMenu[0];
      }
    }
    return currMenu;
  }
  // 获得 selectedKey
  getCurrSelectedKey = (currMenu) => {
    return !!currMenu ? ('' + currMenu.permission.permissionId) : '';
  }
  // 检查权限
  checkAuthorization = (currMenu) => {
    if(!!currMenu)  {
      if(currMenu.type == 'SubMenu') {
        return true;  // SubMenu都可以访问
      }
      const userRoleStr = localStorageClient.getRole();
      const userRoleArray = userRoleStr.split(',');
      userRoleArray.push('CMS_REPORT_USER');  // 默认所有人都有CMS_REPORT_USER权限
      const allowedRoleArray = currMenu.accessableRoleNames;
      let authorized = false;
      for(let i in allowedRoleArray) {
        for(let j in userRoleArray) {
          if(allowedRoleArray[i] === userRoleArray[j]){
            authorized = true;
            break;
          }
        }
        if(authorized) {
          break;
        }
      }
      return authorized;
    }
    return false;
  }

  


  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  logoutButtonOnClicked = () => {
    this.props.logout();
  }

  


  render() {
    const currMenu = this.getCurrMenu();
    if(!currMenu && !!this.props.flatMenus) {  // 第一次render时，flatMenus还不存在
      return (
        <Redirect to="/home/manuals-and-policies" />
      );
    }
    if(!!this.props.flatMenus) {  // 第一次render时，flatMenus还不存在
      // const authorized = this.checkAuthorization(currMenu);
      // if(!authorized) {  
      //   message.error('You are not authorized to access this page.');
      //   return (
      //     <Redirect to="/home/manuals-and-policies" />
      //   );
      // }
    }

    const selectedKey = this.getCurrSelectedKey(currMenu);
    const employee = localStorageClient.getUserName();
    const hierarchyMenuOptions = this.props.hierarchyMenuOptions;

    const logoutMenu = (
      <Menu>
        <Menu.Item key="logout" onClick={this.logoutButtonOnClicked}>
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    
    return (
      <div >
        <style>
          {`            
            .logo {
              height: 32px;
              background: rgba(255,255,255,.2);
              margin: 16px;
            }

            .ant-layout {
              min-height: 100vh;
            }

            .ant-layout-content {
              height: calc(100vh - 44px);
            }

            .ant-layout-header {
              height: 44px;
              padding: 0px;
              display: flex;
              justify-content: space-between;
            }
            .ant-tabs-tabpane {
              height: calc(100vh - 104px);
              margin-top: 0px;
            }
          `}
        </style>
        <Layout>

          <Header>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[selectedKey]}
              style={{ lineHeight: '44px', marginLeft: '20px' }}
            >
              {hierarchyMenuOptions}
            </Menu>
            
            <div style={{ lineHeight: '44px' }}>
              <Dropdown
                overlay={logoutMenu}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
              >
                <span>
                  <Avatar
                    size="small"
                    alt="avatar"
                    style={{ marginRight: 10 }}
                  />
                  <span style={{ color: '#fff', marginRight: 30 }}>{ employee }</span>
                </span>
              </Dropdown>
            </div>
          </Header>


          <Content>
            <Switch>
              <Route path="/home/manuals-and-policies" component={ManualsAndPoliciesContainer} />
              <Route path="/home/personal-trade-request" component={TradeRequestContainer} />
              <Route path="/home/personal-account-holding" component={AccountHoldingContainer} />
              <Route path="/home/gift-hospitality-request"  component={GiftHospitalityRequestContainer} />
              <Route path="/home/restricted-security-request"  component={RestrictedSecurityRequestContainer} />
              <Route path="/home/questionnaire-request"  component={QuestionnaireRequestContainer} />
              <Route path="/home/licensing"  component={LicensingContainer} />
              <Route path="/home/agreement"  component={GeneralAgreementContainer} />
              <Route path="/home/meeting-and-training"  component={MeetingAndTrainingContainer} />
              <Route path="/home/college-application"  component={CollegeApplicationContainer} />
              <Route path="/home/meeting-training-calender"  component={MeetingAndTrainingCalenderContainer} />
              <Route path="/home/expense-summary"  component={ExpenseSummaryNewContainer} />
              <Route path="/home/work-management" component={WorkManagementContainer} />
              <Route path="/home/reporting-summary" component={ReportingSummaryContainer} />
              <Route exact path="/home" render={() => <Redirect to="/home/manuals-and-policies" />} />
            </Switch>
          </Content>


        </Layout>
      </div>
    );
  }
}

export default Home;