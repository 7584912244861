import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button,Select,DatePicker,Checkbox,Upload, Icon ,Tooltip} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const regiStatusOptions = [];
regiStatusOptions.push(<Option key={'Food, Beverage & Tobacco'}>{'Food, Beverage & Tobacco'}</Option>);
regiStatusOptions.push(<Option key={'Capital Goods'}>{'Capital Goods'}</Option>);
regiStatusOptions.push(<Option key={'Banks'}>{'Banks'}</Option>);
regiStatusOptions.push(<Option key={'Insurance'}>{'Insurance'}</Option>);
regiStatusOptions.push(<Option key={'Energy'}>{'Energy'}</Option>);
regiStatusOptions.push(<Option key={'Automobiles & Components'}>{'Automobiles & Components'}</Option>);
regiStatusOptions.push(<Option key={'Consumer Durables & Apparel'}>{'Consumer Durables & Apparel'}</Option>);
regiStatusOptions.push(<Option key={'Technology Hardware & Equipment'}>{'Technology Hardware & Equipment'}</Option>);
regiStatusOptions.push(<Option key={'Semiconductors & Semiconductor Equipment'}>{'Semiconductors & Semiconductor Equipment'}</Option>);
regiStatusOptions.push(<Option key={'Utilities'}>{'Utilities'}</Option>);
regiStatusOptions.push(<Option key={'Health Care Equipment & Services'}>{'Health Care Equipment & Services'}</Option>);
regiStatusOptions.push(<Option key={'Pharmaceuticals, Biotechnology & Life Sciences'}>{'Pharmaceuticals, Biotechnology & Life Sciences'}</Option>);
regiStatusOptions.push(<Option key={'Retailing'}>{'Retailing'}</Option>);
regiStatusOptions.push(<Option key={'Telecommunication Services'}>{'Telecommunication Services'}</Option>);
regiStatusOptions.push(<Option key={'Diversified Financials'}>{'Diversified Financials'}</Option>);
regiStatusOptions.push(<Option key={'Materials'}>{'Materials'}</Option>);
regiStatusOptions.push(<Option key={'Transportation'}>{'Transportation'}</Option>);
regiStatusOptions.push(<Option key={'Real Estate'}>{'Real Estate'}</Option>);
regiStatusOptions.push(<Option key={'Software & Services'}>{'Software & Services'}</Option>);
regiStatusOptions.push(<Option key={'Media & Entertainment'}>{'Media & Entertainment'}</Option>);
regiStatusOptions.push(<Option key={'Household & Personal Products'}>{'Household & Personal Products'}</Option>);
regiStatusOptions.push(<Option key={'Consumer Services'}>{'Consumer Services'}</Option>);
regiStatusOptions.push(<Option key={'Commercial & Professional Services'}>{'Commercial & Professional Services'}</Option>);
regiStatusOptions.push(<Option key={'Food & Staples Retailing'}>{'Food & Staples Retailing'}</Option>);

const markingOptions = [];
markingOptions.push(<Option key={'CH'}>{'CH'}</Option>);
markingOptions.push(<Option key={'JP'}>{'JP'}</Option>);
markingOptions.push(<Option key={'IN'}>{'IN'}</Option>);
markingOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);

// const agree = 'false' 

class ChangeMeetingAndTrainingStatusModalDialog extends Component {

    refreshBtnOnClicked = () => {
        // this.props.loadMeetingAndTrainingInfo();
        this.props.loadMeetingAndTrainingInfo({type:undefined,starttime:undefined,endtime:undefined,speaker:undefined})
    }

    componentDidUpdate() {
        const {
            submitChangeMeetingAndTrainingStatusActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.changeMeetingAndTrainingStatusModal;

        if(submitChangeMeetingAndTrainingStatusActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' succeeded.';
            message.success(msg);
            this.props.initSubmitChangeMeetingAndTrainingStatusModalActionStatus();
            this.props.resetChangeMeetingAndTrainingStatusModal();
            this.refreshBtnOnClicked();
        }
        if(submitChangeMeetingAndTrainingStatusActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitChangeMeetingAndTrainingStatusModalActionStatus();
        }
    }

    getActionFromMode(mode) {
        if(mode === 'Advanced Training') {
            return 'Advanced Training';
        } else if(mode === 'Paper Portfolio') {
            return 'Paper Portfolio';
        } else if (mode === 'Stock Pitch'){
            return 'Stock Pitch'
        }
    }

    onInputChange = ({name, value}) => {
        this.props.changeGeneralAgreemenStatusChangeInput({name, value});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    submitChangeMeetingAndTrainingStatusModal = () => {
        const {
            mode,
        } = this.props.changeMeetingAndTrainingStatusModal;
        const {
            idList,
            begindate,
            sector,
            exception,
            market,
            complianceconfirm,
            uploadOriName,
            uploadRealName,
            // personInCharge,
            // company,
            // counterparty,
            // agreementType,
            // executionDate,
            // expiryDate,
            // noticePeriod,
            // terms,
            // uploadOriName,
            // uploadRealName,
            // amount,
            // amountCCY,
            remark,
            // comments,
            // source,
        } = this.props.changeMeetingAndTrainingStatusModal.fields;

        // Report端只能DEL New状态的
        // if(mode === 'DEL' && status !== 'New') {
        //     message.warn('Only the General Agreements in New status can be Deleted');
        //     return;
        // }
        // // 只有 New、Rejected、Valid状态的可以SUBMIT
        // if(mode === 'SUBMIT' && status !== 'New' && status !== 'Rejected' && status !== 'Valid') {
        //     message.warn('Only the General Agreements in New, Rejected or Valid status can be Submitted');
        //     return;
        // }
        // // 只有Valid状态的可以EXPIRE
        // if(mode === 'EXPIRE' && status !== 'Valid') {
        //     message.warn('Only the General Agreements in Valid status can be Expired');
        //     return;
        // }

        Modal.confirm({
            title: 'Are you sure to ' + this.getActionFromMode(mode) + '?',
            onOk: () => {
                this.props.submitChangeMeetingAndTrainingStatusModal({
                    idList,
                    begindate,
                    sector,
                    exception,
                    market,
                    complianceconfirm,
                    uploadOriName,
                    uploadRealName,
                    remark,
                    // comments,
                    // source,
                    modes: mode,
                    mode: mode,
                });
            }
        });
    }
    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        let attachment = '';
        let attachmentOriginName= '';
        fileList.forEach(file => {
          if (file.response) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        this.onInputChange({name: 'uploadRealName', value: attachment});
        this.onInputChange({name: 'uploadOriName', value: attachmentOriginName});

    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            changeMeetingAndTrainingStatusModal,
            submitChangeMeetingAndTrainingStatusActionStatus,
            meetingAndTrainingUploadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = changeMeetingAndTrainingStatusModal;

        const {
            id,
            remarks,
            begindate,
            complianceconfirm,
            sector,
            exception,
            market,
            agreementUploadFileList,
            uploadOriName,
            comments,
        } = changeMeetingAndTrainingStatusModal.fields;

        const modalTitle = this.getActionFromMode(mode) + '';
        const isSubmitLoading = submitChangeMeetingAndTrainingStatusActionStatus === ACTION_STATUS.LOGINING;
        
      
        return(
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetChangeMeetingAndTrainingStatusModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetChangeMeetingAndTrainingStatusModal() }>
                          Cancel
                        </Button>,
                        // <Button key="Submit" type="primary" disabled={!complianceconfirm} loading={isSubmitLoading} onClick={() => this.submitChangeMeetingAndTrainingStatusModal() }>
                        //   Submit
                        // </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitChangeMeetingAndTrainingStatusModal() }>
                        Submit
                      </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                    <div style={{marginLeft: 80 }}>{
                        mode === 'Paper Portfolio' ?   
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label={<span>
                                        Begin Date&nbsp;
                                    <Tooltip title=" the date you want to have your paper account ready">
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>}>
                                    {getFieldDecorator('beginDate', {
                                        initialValue: begindate,
                                        rules: [{
                                            required: false,
                                            message: 'Please select Begin Date',
                                        }],
                                        })(
                                        <DatePicker 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(date, dateString) => {
                                                this.onInputChange({ name: 'begindate', value: dateString })
                                            }}
                                        />
                                        )}
                                    </FormItem>
                                </Col>
                                </Row>
                                : <div></div>
                            }</div>
                        <div style={{marginLeft: 80 }}>{
                            mode === 'Paper Portfolio' || mode === 'Stock Pitch' ? 
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Sector (tentative)">
                                        {getFieldDecorator('sector', {
                                            initialValue: sector,
                                            rules: [
                                                {
                                                required: true,
                                                message: 'Please select sector',
                                                },
                                                {
                                                    validator: (rule, value, callback) => {
                                                        // 使用正则表达式分割输入值，以分号分隔
                                                        const groups = value.split(';');
                                                        let valid = true;
                                    
                                                        // 遍历每个分组并检查是否为6位或8位数字
                                                        for (const group of groups) {
                                                            const numericValue = group.replace(/\D/g, ''); // 去除非数字字符
                                                            if (numericValue.length !== 6 && numericValue.length !== 8) {
                                                                valid = false;
                                                                break; // 如果有任何一个分组不满足条件，终止循环
                                                            }
                                                        }
                                    
                                                        if (!valid) {
                                                            callback('Input must be 6 or 8 digits for each group, separated by semicolons.eg:123456;12345678');
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                            ],
                                            })(
                                                <div>
                                            <Input
                                            placeholder='sector code' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'sector', value: e.target.value })
                                            }}
                                            />
                                            <a 
                                            href= {BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL+'/MSCI_Global_Industry_Classification_Standard_(GICS®)_Methodology_20230601_20231025095036801.pdf'} 
                                            download="MSCI_Global_Industry">SectorCode Documentation</a>
                                            </div>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row>
                                : <div></div>
                            }</div>
                        <div style={{marginLeft: 80 }}>{
                        mode === 'Paper Portfolio' || mode === 'Stock Pitch'? 
                            <Row gutter={24}>
                            <Col span={12}>
                                    <FormItem {...formItemLayout} label="Market (tentative)">
                                        {getFieldDecorator('market', {
                                            initialValue: market,
                                            rules: [
                                                {
                                                required: true,
                                                message: 'Please select Market',
                                            }],
                                            })(
                                            <Select
                                                    allowClear
                                                    mode='multiple'
                                                    style={{ width: 300, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onEmailInputChange({ name: 'market', value: val })
                                            }}
                                                    placeholder="Please select Market">
                                                {markingOptions}
                                            </Select>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row> 
                                : <div></div>
                            }</div>  

                        <div style={{marginLeft: 80 }}>{
                        mode === 'Paper Portfolio' ?                          
                        <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} 
                            
                            label={<span>
                                Upload(BBGTicker)&nbsp;
                            <Tooltip title=" Download the template, fill it correctly, and upload it">
                            <Icon type="question-circle-o" />
                            </Tooltip>
                            </span>}
                            
                            >
                                {getFieldDecorator('uploadOriName', {
                                    initialValue: uploadOriName,
                                    rules: [{
                                        required: true,
                                        message: 'Please upload the Agreement',
                                    }],
                                    })(
                                    <div>
                                    <Upload
                                        name="uploadOriName"
                                        withCredentials={true}
                                        
                                        // disabled={needDisable}
                                        multiple={true}
                                        showUploadList={true}
                                        fileList={agreementUploadFileList}
                                        action={meetingAndTrainingUploadUrl}
                                        onChange={this.generalAgreeementHandleChange}
                                    >
                                        <Button 
                                            style={{ width: 300, marginLeft: 10 }}
                                            // disabled={needDisable}
                                        >
                                            <Icon type="upload" />Stock lists
                                        </Button>
                                    </Upload>
                                     <a 
                                     href= {BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL+'/Trading Universe Set-up - GICS Code_20231025094718924.xlsx'} 
                                     download="MSCI_Global_Industry">Download Template</a>
                                     </div>
                                )}
                            </FormItem>
                        </Col>

                        
                    </Row>
                        : <div></div>
                    }</div>    

                        <div style={{marginLeft: 80 }}>{
                            mode === 'Paper Portfolio'? 
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} 
                                    label=
                                    {<span>
                                        Exception&nbsp;
                                    <Tooltip title="Stock not in your sector but you still want to trade, BBG full Ticker">
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                    </span>}
                                    >
                                        {getFieldDecorator('exception', {
                                            initialValue: exception,
                                            rules: [
                                                {
                                                required: false,
                                                message: 'Exception Ticker',
                                                },
                                                
                                            ],
                                            })(
                                                <div>
                                            <Input
                                            placeholder='Exception Ticker' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'exception', value: e.target.value })
                                            }}
                                            />
                                        
                                            </div>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row>
                                : <div></div>
                            }</div>
                    <div style={{marginLeft: 80 }}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Note">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 500, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input remarks' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remark', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        </div>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }

}

const ChangeMeetingAndTrainingStatusModalDialogForm = Form.create({ name: 'ChangeMeetingAndTrainingStatusModalDialog' })(ChangeMeetingAndTrainingStatusModalDialog);
export default ChangeMeetingAndTrainingStatusModalDialogForm;

